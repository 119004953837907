<template>
  <div class="container-fluid pt-5">
    <div class="row d-flex align-items-stretch h-100">
      <!-- Left Side -->
      <div class="col-md-5 ps-4 d-flex flex-column">
       <div class="mb-4">
          <h5 class="fw-bold text-blue" @click="toggleCaseInfo">
            {{ "Case Info" }}
            <span class="material-symbols-outlined float-right accordion-icon" :class="{ 'rotate-icon': isExpanded }" style="cursor:pointer !important;">
              expand_less
            </span>
          </h5>
          <div v-show="isExpanded">
            <b>{{ "Case" }}</b>
            <p>{{ client_case }}</p>
            <template v-if="pre_event">
              <div class="row">
                <div class="col">
                  <b>{{ "Background/Facts of The Case" }}</b>
                  <p>{{ pre_event }}</p>
                </div>
              </div>
            </template>

            <template v-if="pre_event_file">
              <div class="row">
                <div class="col-3 d-flex flex-column align-items-start">
                  <b>{{ "Background/Facts of The Case" }}</b>
                  <button @click="openPDF" class="mt-1 btn btn-primary">
                    Open File
                  </button>
                </div>
              </div>
            </template>
          </div>
        </div>
       <div class="card mb-4 d-flex border rounded shadow-sm flex-column">
          <div class="p-2 flex-grow-0">
            <h6 class="fw-bold text-muted">List of Prompts</h6>
            <div style="max-height: 200px; overflow-y: auto;">
              <ul>
                <li v-for="(question, index) in default_questions" 
                    :key="index" 
                    @click="chooseMessage(question, index)"
                    :disabled="loadAnswer || question.displayed" 
                    style="cursor:pointer;">
                  {{ question.text }}
                </li>
              </ul>
            </div>
          </div>
        </div>

       <div class="mb-4">
        <div class="d-flex justify-content-between align-items-center mt-2 upload">
          <input
            class="form-control me-2" 
            style="border:none;"
            type="file"
            id="formFile"
            ref="PreEventFile"
            accept=".png,.jpeg,.jpg,.pdf,.docx,.mp3"
            multiple 
            @change="handleFileUpload"
          />

          <span 
            class="material-symbols-outlined upload-icon me-2"  
            @click="uploadDocuments" 
            :style="{ cursor: isUploading ? 'not-allowed' : 'pointer' }"
            :class="{ 'disabled-icon': isUploading }"
          >
            upload
          </span>
        </div>
        <small class="font-12 mx-1 text-center d-flex align-items-center">
          <b-icon class="mx-1" icon="info-circle" />
          Please upload PDF,PNG,JPEG,JPG,MP3 or Document file only
        </small>
      </div>

        <div class="card mb-4 border rounded shadow-sm flex-grow-1 d-flex flex-column">
          <div class="p-2">
            <h6 class="fw-bold text-muted">List of Documents Uploaded</h6>
            <PageLoader v-if="uploadDoc" :duration="duration" :message="['Adding New Uploading Documents Files']"></PageLoader>
            <ol v-if="!uploadDoc" style="max-height: 250px; overflow-y: auto;">
              <li v-for="(doc, index) in uploadedDocuments" :key="index">
                {{ doc.name }}
              </li>
            </ol>
          </div>
        </div>
      </div>

      <!-- Right Side -->
      <div class="col-md-7 d-flex flex-column">
        <div class="card border shadow-sm px-2 py-2 rounded mt-3 content-card flex-grow-1">
          <div class="d-flex p-3">
            <h4 class="p-2 text-blue" style="background-color: #f1f1f1; border-bottom: 2px solid #094890; border-radius: 20px 20px 0 0;">
              <b>LawBot</b>
            </h4>
          </div>
          <div class="card border-0 card_question flex-grow-1">
            <div class="chat__box" id="messages" ref="messages1">
              <!-- Incoming Chat box -->
              <div v-for="(message, idx) in chatMessages" :key="idx" :class="message.class_1">
                <div :class="message.class_2">
                  <div :class="message.class_3">
                    <span v-if="isTableFormat(message.text)">
                      <table v-if="parseTableData(message.text)" class="table table-bordered custom-table">
                        <thead>
                          <tr>
                            <th v-for="(header, index) in getTableHeaders(message.text)" :key="index">
                              {{ header }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(row, rowIndex) in parseTableData(message.text)" :key="rowIndex">
                            <td v-for="(value, key, cellIndex) in row" :key="cellIndex">
                              {{ value }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </span>
                    <span v-else v-html="formatText(message.text)"></span>
                  </div>
                </div>
              </div>

              <!-- Outgoing Chat Box -->
              <div v-if="loadAnswer" class="chat__incoming">
                <div class="chat__bubble__wrapper-incoming">
                  <div class="chat__bubble-incoming animated-background-incoming">
                    <div style="transform: scale(0.7)" class="circles-to-rhombuses-spinner">
                      <div class="circle"></div>
                      <div class="circle"></div>
                      <div class="circle"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Chat Input -->
            <div class="chat__input">
              <input :disabled="loadAnswer" @keyup.enter="sendNewMessage" type="text" v-model="NewText" placeholder="Ask Questions about the Case..." />
              <button @click="sendNewMessage" :disabled="loadAnswer" class="btn-send">
                <span class="material-icons-sharp">arrow_circle_right</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LES from "@/store/LES.js";
import Auth from "@/store/Auth.js";
import {validateInput } from "../../store/utils";
import PageLoader from "../../components/PageLoader.vue";
export default {
components: {
  PageLoader,
},
data(){
    return{
    isExpanded: false,
    isUploading: false,
    uploadDoc:false,
    duration:0,
    client_case: "",
    client_type: "",
    jurisdiction: "",
    pre_event: "",
    pre_event_file: "",
    courtname: "",
    prompts: [
        "Does the contract comply with my policy?",
        "Is the dispute resolution under Australian jurisdiction?",
      ],
      uploadedDocuments: [], 
      chatMode: 'advanced',
      mode:"", // Default mode is normal
      uploadedFiles: [], // Holds files before uploading
    // chatbot
     default_questions:[
      { text: "Generate a table of expert witnesses and their qualifications.", displayed: false },
      { text: "What are the common defenses in this type of case?", displayed: false },
      { text: "Compare different legal strategies of this case in a table.", displayed: false },
      { text: "What are the key deadlines for filing motions in this trial?", displayed: false },
      { text: "Generate a SWOT analysis of our case.", displayed: false },
      { text: "Generate a letter to a witness requesting their cooperation for the trial.", displayed: false },
      { text: "Draft a formal email to the court requesting a hearing date.", displayed: false },
      { text: "Write a summary letter to the client explaining the trial strategy.", displayed: false },
      { text: "Help draft a settlement agreement.", displayed: false },
      { text: "List the arguments for and against our position in a pros and cons table.", displayed: false },
      { text: "What are the requirements for expert witness qualifications?", displayed: false },
      { text: "Create discovery requests and responses in a table form.", displayed: false },
      { text: "What evidence do we need to support our claims?", displayed: false },
      { text: "Generate a timeline of key events for our case history.", displayed: false },
      { text: "Analyze the potential impact of recent case law on our strategy.", displayed: false },
      { text: "What documents are required for pre-trial preparation?", displayed: false },
      { text: "Draft a demand letter to the opposing party outlining our claims.", displayed: false },
      { text: "Create a follow-up email for the client regarding their case status.", displayed: false },
      { text: "Compile case precedents and their relevance in a table.", displayed: false },
      { text: "Generate a motion to dismiss for this case.", displayed: false },
      { text: "List potential jury demographics and their implications in a table.", displayed: false },
      { text: "What questions should we ask during witness depositions?", displayed: false },
      { text: "Create risk assessment in a table for potential outcomes.", displayed: false },
      { text: "Summarize the opposing party's arguments in a table format.", displayed: false },
      { text: "Generate cost-benefit analysis in a table for going to trial vs. settling.", displayed: false }
  ],

      NewText: "",
      loadAnswer: false,
      chatMessages: [],
      interval: null,
    }
},
created() {
    LES.GetLES(this.$route.params.id)
      .then((response) => {
        const DATA = response.data.data;
          this.uploadedDocuments=DATA.questionDocuments;
          this.client_case = DATA.client_case;

          this.client_type = DATA.client_type;
          this.pre_event = DATA.pre_event;
          this.pre_event_file = DATA.pre_event_file;


        const OutMessage = {
          class_1: "chat__outgoing",
          class_2: "chat__bubble__wrapper-outgoing",
          class_3: "chat__bubble-outgoing animated-background-outgoing",
          new: false,
        };
        const InMessage = {
          class_1: "chat__incoming",
          class_2: "chat__bubble__wrapper-incoming",
          class_3: "chat__bubble-incoming animated-background-incoming",
          new: false,
        };
          // LawBot Code
        this.mode = 'advanced'; 
        const filteredQuestions = DATA.questions.filter((element) => element.mode === this.mode); 
        filteredQuestions.forEach((element) => {
          if ((element.answer?.length ?? false) != 0) {
            this.default_questions.splice(
              this.default_questions[
                this.default_questions.findIndex((x) => x === element.question)
              ],
              1
            );

            OutMessage.text = element.question;
            this.chatMessages.push({...OutMessage});

            try {
              JSON.parse(element.answer).forEach((answer) => {
                InMessage.text = answer;
                this.chatMessages.push({...InMessage});
              });
            } catch (error) {
              InMessage.text = element.answer
              this.chatMessages.push({...InMessage});
            }
          }
        });

        if (
          DATA.questions.length != 0 &&
          DATA.questions[DATA.questions.length - 1].answer == null
        ) {
          this.loadAnswer = true;
          this.calltime("none");

          OutMessage.text = DATA.questions[
            DATA.questions.length - 1
          ].question;
          this.chatMessages.push(OutMessage);
        }

        if (this.chatMessages.length == 0) {
          InMessage.text = "You may ask question about the case here🔎";
          this.chatMessages.push(InMessage);
        }
        });
    },
methods:{
  toggleCaseInfo() {
      this.isExpanded = !this.isExpanded;
    },
    toastError(message) {
      if (message === "empty") {
        this.$toast.error("Please fill in all required fields");
      } else {
        this.$toast.success(message);
      }
    },
    sendPrompt(prompt) {
      this.$emit('send-chat-message', prompt);
      this.chatMessage = prompt;
      this.sendNewMessage();
    },
     isTableFormat(text) {
      try {
        const parsedText = JSON.parse(text);
        return Object.prototype.hasOwnProperty.call(parsedText, 'table');
      } catch (error) {
        return false;
      }
      },
      parseTableData(text) {
        try {
          const parsedText = JSON.parse(text);
          return parsedText.table;
        } catch (error) {
          console.error("Failed to parse table data:", error);
          return [];
        }
      },
      getTableHeaders(text) {
        try {
          const parsedText = JSON.parse(text);
          const table = parsedText.table;
          if (table && table.length > 0) {
            // Return the keys of the first row as headers
            return Object.keys(table[0]);
          }
          return [];
        } catch (error) {
          console.error("Failed to get table headers:", error);
          return [];
        }
      },
      formatText(text) {
        const stringPattern = /^\{"string":\s*"(.*)"\}$/;
        const match = text.match(stringPattern);

        if (match) {
          text = match[1];
        }
        return text
          .replace(/\\n*/g, '<br>')
          .replace(/\\n\\n*/g, '<br>')
          .replace(/\\n\\n/g, '<br>')
          .replace(/\\n/g, '<br>')
          .replace(/\n/g, '<br>')
          .replace(/\n\n*/g, '<br>')
          .replace('*', '')
          .replace(/\\/g, '<br>');
      },
    handleFileChange(event) {
        var result = validateInput(event.target.files[0], "file", ["pdf", "docx"]);
        if (!result[0] && result[1] !== "empty") {
          this.toastError(result[1]);
          this.pre_event_file = null;
          this.$refs.PreEventFile.value = null;
        } else {
          this.pre_event_file = result[1] === "empty"
            ? null : result[1];
        }
      },
     handleFileUpload(event) {
      this.uploadedFiles = Array.from(event.target.files || []);
      if (!this.uploadedFiles.length) {
        this.toastError("empty");
      }
    },
    // async uploadDocuments() {
    //   const formData = new FormData();
    //   this.uploadedFiles.forEach((file, index) => {
    //     formData.append(`question_doc[${index}]`, file);
    //   });

    //   try {
    //     await LES.uploadDocument(this.$route.params.id, formData);
    //       console.log('Documents uploaded successfully!');
    //   } catch (error) {
    //     console.error('Error uploading documents:', error);
    //   }
    // },
    //  handleFileUpload(event) {
    //     this.uploadedFiles = Array.from(event.target.files);
    //     if (this.uploadedFiles.length > 0) {
    //       this.uploadDocuments();
    //     }
    //   },
      async uploadDocuments() {
        this.isUploading = true;

        if (!this.uploadedFiles || this.uploadedFiles.length === 0) {
          this.toastError("empty");
          this.isUploading = false; 
          return;
        }

        const formData = new FormData();
        this.uploadedFiles.forEach((file, index) => {
          formData.append(`question_doc[${index}]`, file);
        });

        try {
          const response = await LES.uploadDocument(this.$route.params.id, formData);
          if (response) {
            this.uploadDoc = true;
            this.toastError("File Upload Successfully");

            // Reset input field and uploadedFiles array
            this.$refs.PreEventFile.value = "";  // Clear the input field
            this.uploadedFiles = [];  // Clear the uploadedFiles array
            const res = await LES.GetLES(this.$route.params.id);
            const DATA = res.data.data;
            this.uploadedDocuments = DATA.questionDocuments;
            const incompleteDocument = DATA.questionDocuments.find(doc => doc.completePercentage < 100);

      // Clear any existing intervals
      if (this.interval) {
        clearInterval(this.interval);
      }

      if (incompleteDocument) {
        // If there's a document with completePercentage < 100, start the loading
        this.uploadDoc = true;
        this.duration = incompleteDocument.completePercentage;

        this.interval = setInterval(async () => {
          try {
            const res = await LES.GetLES(this.$route.params.id);
            const updatedData = res.data.data;
            this.uploadedDocuments = updatedData.questionDocuments;

            // Check if all documents are complete (i.e., completePercentage >= 100)
            const incompleteDoc = updatedData.questionDocuments.find(doc => doc.completePercentage < 10);
            
            if (!incompleteDoc) {
              // All documents are complete, stop the loader and clear the interval
              this.uploadDoc = false;
              clearInterval(this.interval);
            }
          } catch (error) {
            console.error("Error fetching updated documents:", error);
          }
        }, 10000);
      }
          }
        } catch (error) {
          this.toastError("Error uploading documents.");
          console.error('Error uploading documents:', error);
        } finally {
          this.isUploading = false;
        }
      },
    chooseMessage(message, index) {
        this.loadAnswer = true;
        this.chatMessages.push({
          text: message.text,
          class_1: "chat__outgoing",
          class_2: "chat__bubble__wrapper-outgoing",
          class_3: "chat__bubble-outgoing animated-background-outgoing",
          new: false,
        });

        this.default_questions[index].displayed = true;
        const formData = new FormData();
        formData.append("question", message.text); 
        formData.append("mask", "false");           
        formData.append("mode", this.chatMode);
        for (let pair of formData.entries()) {
          console.log(`${pair[0]}: ${pair[1]}`);
        }

        // Send the formData to the API
        LES.sendQuestion("les", this.$route.params.id, formData)
          .then(() => {
            this.default_questions.splice(
              this.default_questions.findIndex((x) => x.text === message.text),
              1
            );
            this.calltime("default");
          })
          .catch((error) => {
            console.log('Error sending question:', error);
            this.loadAnswer = false;
          });

        // Scroll the chat to the latest message
        this.$nextTick(() => {
          const messageDisplay = this.$refs.messages1;
          messageDisplay.scrollTop = messageDisplay.scrollHeight;
        });
      },
    sendNewMessage() {
      if (this.NewText.length != 0) {
        this.loadAnswer = true;
        this.chatMessages.push({
          text: this.NewText,
          class_1: "chat__outgoing",
          class_2: "chat__bubble__wrapper-outgoing",
          class_3: "chat__bubble-outgoing animated-background-outgoing",
          new: false,
        });

        // Create a new FormData object
        const formData = new FormData();
        formData.append("question", this.NewText);
        formData.append("mask", "false");
        formData.append("mode", this.chatMode);

        // Clear the NewText input
        this.NewText = "";

        for (let pair of formData.entries()) {
      console.log(pair[0] + ': ' + pair[1]);
    }

        // Use the formData object to send the request
        LES.sendQuestion("les", this.$route.params.id, formData)
          .then(() => {
            Auth.getMe().then((res) => {
              localStorage.setItem("userInfo", JSON.stringify(res.data.data));
              const credits = res.data.data.nexlaw_credits;
              this.contract_questions_full = credits.full.contract_questions;
              this.contract_questions_remain =
                credits.full.contract_questions - credits.used.contract_questions;
            });
            this.calltime("none");
          })
          .catch((error) => {
            this.loadAnswer = false;
            console.log(error);
          });

        // Scroll the chat to the latest message
        var div = document.getElementById("messages");
        div.scrollTop = div.scrollHeight;

        this.$nextTick(() => {
          var messageDisplay = this.$refs.messages1;
          messageDisplay.scrollTop = messageDisplay.scrollHeight;
        });
      }
    },

    calltime(data) {
      var me = this;
      setTimeout(function () {
        if (!me.$route.params.id) return;

        LES.GetLES(me.$route.params.id)
          .then((response) => {
            const questions = response.data.data.questions;
            const last_q = questions[questions.length - 1];
            if (last_q.answer != null) {
              try {
                JSON.parse(last_q.answer).forEach((answer) => {
                  me.chatMessages.push({
                    text: answer,
                    class_1: "chat__incoming",
                    class_2: "chat__bubble__wrapper-incoming",
                    class_3:
                      "chat__bubble-incoming animated-background-incoming",
                    new: true,
                  });
                });
              } catch (error) {
                me.chatMessages.push({
                  text: last_q.answer,
                  class_1: "chat__incoming",
                  class_2: "chat__bubble__wrapper-incoming",
                  class_3: "chat__bubble-incoming animated-background-incoming",
                  new: true,
                });
              }
              me.loadAnswer = false;

            //   if (data == "default") {
            //     me.default_questions.splice(index, 1);
            //   }
            } else {
              me.calltime(data);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }, 5000);
    },
    destroyed() {
    clearInterval(this.interval);
  },

  beforeDestroy() {
    clearInterval(this.interval);
  },
  }
}
</script>
<style scoped>
.rotate-icon {
  transform: rotate(-180deg);
  cursor:pointer !important;
}
.container-fluid{
  height: 100%;
}
.chat-messages {
  max-height: 300px;
  overflow-y: auto;
}
/* chatBot Css */
.animated-background-outgoing {
  background: linear-gradient(
    45deg,
    #012741,
    #004877,
    #006bb3,
    #0866a5,
    #0976be
  );
  background-size: 200% 100%;
  animation: gradientAnimation 3s linear infinite;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 0%;
  }

  25% {
    background-position: 100% 0%;
  }

  50% {
    background-position: 100% 100%;
  }

  75% {
    background-position: 0% 100%;
  }

  100% {
    background-position: 0% 0%;
  }
}

.animated-background-incoming {
  background: linear-gradient(
    45deg,
    #cccaca,
    #bcbebe,
    #d4cece,
    #d3d4d4,
    #f1f1f1
  );
  background-size: 200% 100%;
  animation: gradientAnimation 3s linear infinite;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 0%;
  }

  25% {
    background-position: 100% 0%;
  }

  50% {
    background-position: 100% 100%;
  }

  75% {
    background-position: 0% 100%;
  }

  100% {
    background-position: 0% 0%;
  }
}

.card_question {
  height: 586px;
}

.chat__box {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  height: 100%;
  overflow-y: scroll;
}

.chat__incoming {
  margin-right: auto;
  margin-top: 0.5rem;
}

.chat__bubble__wrapper-incoming {
  margin-right: auto;
  margin-bottom: 1rem;
}

.chat__bubble-incoming {
  padding: 0.3rem 1rem;
  background-color: #f1f1f1;
  border-radius: 30px;
  border-bottom-left-radius: 0px;
  margin-right: 3rem;
  margin-left: 0.5rem;
}

.chat__bubble-incoming span {
  color: #333333;
}

.chat__outgoing {
  margin-left: auto;
  margin-top: 0.5rem;
}

.chat__bubble__wrapper-outgoing {
  margin-left: auto;
  margin-bottom: 1rem;
}

.chat__bubble-outgoing {
  padding: 0.3rem 1rem;
  background-color: #86d4f8;
  border-radius: 30px;
  border-bottom-right-radius: 0px;
  margin-left: 3rem;
}

.chat__bubble-outgoing span {
  color: #fff;
}

/* chat suggestions and input */
.chat__suggestion {
  display: flex;
  border: 2px solid #dddddd;
  border-radius: 20px;
  padding-left: 10px;
  height: auto;
  margin-bottom: 0.5rem;
}

.chat__suggestion:hover {
  background-color: #f1f1f1;
  transition: 300ms ease-out;
}

.chat__suggestion span {
  text-align: left;
  padding-top: 0.2rem;
}

.chat__input {
  display: flex;
  border: 2px solid #0058a2;
  border-radius: 20px;
  padding-left: 10px;
}

.chat__input input {
  border: none;
  border-radius: 20px;
  height: 40px;
  width: 100%;
  box-shadow: none;
  outline: none;
}

.chat__input .btn-send {
  padding: 0;
  margin: 0;
  height: 38px;
}

.chat__input .btn-send span {
  color: #0058a2;
  font-size: 40px;
}

.chat__input .btn-send:hover span {
  color: #004278;
  transition: 300ms ease-out;
}
.upload{
  border: 1px solid #dee2e6;
  border-radius: 8px;
}
.upload-icon {
  font-size: 30px;
  color: #0058a2;
  transition: color 0.2s ease;
}
.custom-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
  font-size: 14px;
}
.custom-table th {
  background:none;
  border: 1px solid #000;
}
.custom-table td {
  background:none;
  padding: 12px 15px;
  text-align: left;
  border: 1px solid #000;
}
</style>